import {
  ControllerParams,
  CreateControllerFn,
} from 'yoshi-flow-editor-runtime';
import { appName } from '../../../.application.json';

const createController: CreateControllerFn = async ({
  controllerConfig,
  flowAPI,
}: ControllerParams) => {
  const { setProps, config } = controllerConfig;
  const { isMobile, inEditor } = flowAPI;

  const spotifyUri = config.publicData.COMPONENT?.spotifyURI;

  return {
    async pageReady() {
      setProps({
        spotifyUri,
        appName,
        inEditor,
        mobile: isMobile(),
      });
    },
    updateConfig($w: unknown, newConfig: any) {
      setProps({
        spotifyUri: newConfig.publicData.COMPONENT?.spotifyURI,
      });
    },
  };
};

export default createController;
