
  import {createControllersWithDescriptors, initAppForPageWrapper} from 'yoshi-flow-editor-runtime/build/esm/viewerScript.js';
  
  
            import controller0 from '/home/builduser/agent00/work/ad4ac254fb89a706/spotify-ooi/src/components/Spotify/controller.ts';

            import controller1 from '/home/builduser/agent00/work/ad4ac254fb89a706/spotify-ooi/src/components/SpotifySearch/controller.ts';

  
  import * as viewerApp from '/home/builduser/agent00/work/ad4ac254fb89a706/spotify-ooi/src/viewer.app.ts';
    var importedApp = viewerApp;

  import {
    
  } from 'yoshi-flow-editor-runtime/build/esm/controller/httpClientProp';

  var sentryConfig = {
      DSN: 'https://01fb0c955b864231aa35321b27af56a8@sentry.wixpress.com/440',
      id: '01fb0c955b864231aa35321b27af56a8',
      projectName: 'spotify',
      teamName: 'music',
    };

  var experimentsConfig = {"scope":"spotify"};

  var translationsConfig = null;

  var defaultTranslations = null;

  var biLogger = null;

  export const initAppForPage = initAppForPageWrapper({
    initAppForPage: importedApp.initAppForPage,
    sentryConfig: sentryConfig,
    experimentsConfig: experimentsConfig,
    inEditor: false,
    biLogger: biLogger,
    projectName: "spotify-ooi",
    biConfig: null,
    appName: "Spotify",
    appDefinitionId: "099ac204-be88-4ed1-89af-cf14358c83f1",
    translationsConfig: translationsConfig,
    defaultTranslations: defaultTranslations,
    optionalDeps: {
      
    },
  });

  export const createControllers = createControllersWithDescriptors({
     
  }, [{ method: controller0,
          widgetType: "WIDGET_OUT_OF_IFRAME",
          translationsConfig: translationsConfig,
          experimentsConfig: {"scope":"spotify"},
          defaultTranslations: defaultTranslations,
          biLogger: biLogger,
          biConfig: null,
          controllerFileName: "/home/builduser/agent00/work/ad4ac254fb89a706/spotify-ooi/src/components/Spotify/controller.ts",
          appName: "Spotify",
          appDefinitionId: "099ac204-be88-4ed1-89af-cf14358c83f1",
          projectName: "spotify-ooi",
          componentName: "Spotify",
          id: "53d0a5fa-3da5-418b-84d6-e58bd55fecad" }, { method: controller1,
          widgetType: "WIDGET_OUT_OF_IFRAME",
          translationsConfig: translationsConfig,
          experimentsConfig: {"scope":"spotify"},
          defaultTranslations: defaultTranslations,
          biLogger: biLogger,
          biConfig: null,
          controllerFileName: "/home/builduser/agent00/work/ad4ac254fb89a706/spotify-ooi/src/components/SpotifySearch/controller.ts",
          appName: "Spotify",
          appDefinitionId: "099ac204-be88-4ed1-89af-cf14358c83f1",
          projectName: "spotify-ooi",
          componentName: "SpotifySearch",
          id: "spotifySearch" }], "all");
